import { produce } from "immer";
import { filter } from "lodash";
import { DEFAULT_LISTING_COLUMN_SETS, HIERARCHY_COLUMN, DEFAULT_RECOGNITION_COLUMN_SETS } from "../common/columns";
import {
  SET_COLUMN_SET,
  ADD_CUSTOM_COLUMN_SET,
  ADD_COLUMN_TO_SET,
  REMOVE_COLUMN_FROM_SET,
  SET_RECOGNITION_COLUMN_SET,
  ADD_CUSTOM_RECOGNITION_COLUMN_SET,
} from "../actions/columnSetActions";
import { ColumnSetState, ColumnSetAction } from "../common/columnsTypes";

export const initState: ColumnSetState = {
  columnSet: DEFAULT_LISTING_COLUMN_SETS.RECOGNITIONS,
  recognitionColumnSet: DEFAULT_RECOGNITION_COLUMN_SETS.RECOGNITIONS,
  customColumnSets: [],
  customRecognitionColumnSets: [],
};

export default function columnSetReducer(
  state: Readonly<ColumnSetState> = initState,
  action: ColumnSetAction
): ColumnSetState {
  switch (action.type) {
    case SET_COLUMN_SET:
      return produce(state, newState => {
        if (action.columnSet) {
          newState.columnSet = action.columnSet;
          if (action.filters && action.filters.hierarchy) {
            newState.columnSet.columns.unshift(HIERARCHY_COLUMN);
          }
        }
      });
    case ADD_CUSTOM_COLUMN_SET:
      return produce(state, newState => {
        if (action.columnSet) {
          newState.customColumnSets.push(action.columnSet);
          newState.columnSet = action.columnSet;
        }
      });
    case ADD_COLUMN_TO_SET:
      return produce(state, newState => {
        const column = action.column;
        if (column && newState.columnSet.columns[0].id !== column.id) {
          newState.columnSet.columns.unshift(column);
        }
      });
    case REMOVE_COLUMN_FROM_SET:
      return produce(state, newState => {
        const column = action.column;
        if (column) {
          newState.columnSet.columns = filter(newState.columnSet.columns, col => col.id !== column.id);
        }
      });
    case SET_RECOGNITION_COLUMN_SET:
      return produce(state, newState => {
        if (action.columnSet) {
          newState.recognitionColumnSet = action.columnSet;
        }
      });
    case ADD_CUSTOM_RECOGNITION_COLUMN_SET:
      return produce(state, newState => {
        if (action.columnSet) {
          newState.customRecognitionColumnSets.push(action.columnSet);
          newState.recognitionColumnSet = action.columnSet;
        }
      });
    default:
      return state;
  }
}
